export const faqs = [
  {
    ques: "What is Lyamii",
    ans: "Lyamii is an Indian Government recognised forward-thinking traveltech startup. This dynamic platform specialises in offering unique foreign gateways to the users.",
  },
  {
    ques: "Meaning of Lyamii",
    ans: "No such meaning exists, although we believe Lyamii is the invitation to celebrate life to the fullest extent.",
  },
  {
    ques: "What services does Lyamii provide",
    ans: "Lyamii operates complete tour flows in several countries, the Tour IDs connect all dots including domestic flights, accomodation, transports, meals, tour guides, entry passes, activities and round the clock multilingual online-offline support with our 3500+ volunteers.",
  },
  {
    ques: "Types of tour on Lyamii",
    ans: "Lyamii operates tours in two formats, foreign tours covering 35+ countries to Indian audiences and 35+ Indian tours of different flavours to our foreign visitors. Indian tours for our guests come under the Incredible India segment and foreign tours for Indian passport holders come under the Beyond Obvious segment.",
  },
  {
    ques: "How do team Lyamii operate",
    ans: "In Lyamii the operations team is called Nexus. Our Nexus team is more than just a department; it's a commitment to excellence. It has 4 wings and 4 houses. The four wings are Documentation & Verification, Booking & Cancellation, Payment & Refund, Shipment & Warehouse. The wings handle the backend operations, and The houses work on Live Tour Assistance & Problem Solving. Four houses are virtually formed to provide round-the-clock multilingual support to our Users worldwide. Nexus operates a chain of actions for the traveller, and is dedicated to a seamless experience for the user from the beginning to the end with precision and care. Lyamii is always there to ensure the travellers' needs are met at every stage of their Journey.",
  },
  {
    ques: "How do volunteers offer ground support in Lyamii",
    ans: "Beside the confidence of pre-booking in all stays, the user will also have ground support from 3500+ Lyamii Volunteers (Brothers/Sisters), that span all over the globe to assist the traveller on the go. Visitors receive updates and information about the assigned volunteer on his dashboard during a live tour.",
  },
  {
    ques: "How do Lyamii maintain the pricing",
    ans: "Lyamii deals with no middle man. All host and activity bookings from Lyamii come at a special pricing as Lyamii guarantees continued flow of guests to our business partners. We prioritise the value of our product over everything. All our volunteers are trained individuals with experience and excellence in hospitality and communication.",
  },
  {
    ques: "Who're Lyamii's best suited audiences",
    ans: "Lyamii caters a huge variety of tours. The visitors of our platform enjoy a complete look on the world of world tourism and compare his desirable destinations at every possible budget. Ocean, mountain, forests, mediaeval lands, islands everything is covered. In Lyamii we craft time with memories. If you're a travel enthusiast, Lyamii is a must have in your phone. ",
  },
  {
    ques: "Tour ID includes",
    ans: "All tour IDs in all segments include all domestic flights, accomodations, currency exchange, travel insurance, transports, cuisines, sightseeings, tour guides, entry passes, show tickets, round the clock multilingual support, activities and trained volunteers to assist the user on foreign soil. ",
  },
  {
    ques: "How big is the Lyamii community",
    ans: "The Lyamii community covers 40+ countries and 300+ cities around the globe. With 4 houses and 3500+ volunteers we guarantee 24×7 multilingual online and offline assistance. From ticketing executive to onspot cab driver, you have everything covered within Team Lyamii.",
  },
  {
    ques: "What if I book a tour then cancel",
    ans: "Beyond Obvious tours: Once you buy or book a destination you receive a confirmation call within 2 hours to make sure if it was done by mistake or on purpose, with your confirmation we'll proceed to dispatch our shipment else the whole paid amount will be reversed to its source account.Incredible India tours: Once you buy or book a destination you receive a confirmation call within 2 hours to make sure if it was done by mistake or on purpose, with your confirmation we schedule a documentation.For more details kindly go through Lyamii's Cancellation Policy.",
  },
  {
    ques: "How smooth is the refund process",
    ans: "Now 100% user satisfaction is confirmed as recently we have introduced the Pay on Arrival feature in our Incredible India segment. This feature restricts all flaws of online transactions and all possible doubts a first time user might face. The refund process is super comfortable in the Beyond Obvious segment too. Payments are synchronised with the delivery of Lyamii boxes at your doorstep and can be paid in instalments in both online-offline formats. For more details kindly go through Lyamii's Cancellation Policy.",
  },
  {
    ques: "How to overcome language barriers overseas",
    ans: "With the tourID you enjoy basic language lessons on your user dashboard on a live tour. In the Lyamii box, a mini language kit also gets delivered to boost your confidence in foreign language while you're exploring overseas. Be rest assured you face zero barriers communicating with Lyamii's multilingual support, that is always there to ensure the smoothness of your journey. ",
  },
  {
    ques: "How to add individuals in a tour ID",
    ans: "After the individual completes generating his own Travellers ID you can add him in your Tour from the dashboard. Once the newly added Traveller approves the request the Tour ID gets merged and both of the users receive a confirmation call from Lyamii. 50+ destinations around the globe offer flat 30% off on merged tours.",
  },
  {
    ques: "How safe are Lyamii stays",
    ans: "Highly safe and comfortable accomodation is guaranteed. All our stays pass all our requirements and are well reviewed and professional. Be it in any major city world wide, or any offbeat location, 1200+ Lyamii stays have your back on any given day. ",
  },
  {
    ques: "Where to meet the tour guides and volunteers",
    ans: "In 80% of the tours the tour guide meets you at your hostel/hotel reception but in some special cases such as snorkelling, whale watching, you meet your guide at a pre-mentioned location. The live tour dashboard presents the details of the guide and the navigation. During emergency cases we share your current location to the nearest volunteer for the earliest resolution.",
  },
  {
    ques: "How to redeem a surprise treat",
    ans: "With special coupons now add more activities, surprise treats, free journal, souvenir items to your Tour ID, to redeem these coupons you have our round the clock chat support to verify these codes on live chat on a live tour.",
  },
  {
    ques: "How do volunteers work at Lyamii",
    ans: "Objective based. From medical staff to cab drivers, 3500+ Lyamii volunteers handle ground problems with ease. We understand the requirements of a traveller in a foreign land, on a live tour every dot is connected with care. With Lyamii, ground support is covered at every terrain of your desired destination. ",
  },
  {
    ques: "How to raise an issue on an ongoing tour",
    ans: "You have round the clock chat support and multilingual live agents on your user dashboard during an ongoing tour. You may also ask your assigned guide to raise the issue from his end. On the live tour, any kind of technical glitches or ground issues get solved as a priority.",
  },
  {
    ques: "How to reach the bootcamp to report any critical issues",
    ans: "The bootcamp handles red calls such as war, earthquake, tsunami, landslides. A user has access to connect the bootcamp via the chat support itself, or for direct communication write your location in details to connect.bootcamp@lyamii.com, the bootcamp is always just one mail away. Depending on the situation bootcamp works with NDMA, embassies, local police station, med support and everything to ensure the best possible outcome. ",
  },
  {
    ques: "What are the Traveller ID and Tour ID",
    ans: "The traveller ID is the user's unique identification number. On a live tour the user gets verified with this ID during the check ins and ground supports. A raised issue on chat support also gets verified with this ID. The tour ID is the single unique identification number of all the bookings made for the tour flow including flights, accomodation, restaurants, shows etc. This ID ensures the smoothest transition of the user from one place to another. ",
  },
  {
    ques: "What function does the User Dashboard play during a live tour",
    ans: "The dashboard is your one stop interface for the vacation. From receiving live updates on the day to day itinerary to verification codes and 24×7 support, now also stay informed on local guidelines, customs on your dashboard. The live tour dashboard also offers handpicked souvenir items and delivery updates of Lyamii boxes. ",
  },
  {
    ques: "Where to start",
    ans: "Sign up and generate your own Traveller ID on Lyamii, then proceed to verify your ID with your passport. Once you have your identity verified you have access to buyout any vacation from the list directly. Visitors with yet to verify accounts do not have access to the direct purchase option, although the visitor may place the book now option to initiate the process. The desired vacation will be reflected on your Upcoming Tours section once you buy or book an item. From Lyamii the confirnimation call, video call presentation and documentation will be conducted accordingly. After the documentation the Tour ID will be shared with the user and hosts. This ID will cover every possible requirement of the traveller once the tour gets live. ",
  },
  {
    ques: "How does Lyamii operate long vacations seamlessly",
    ans: "After taking feedback from several world tourists visiting India, we've understood the diverse energy our country contains is very different and powerful. Keeping the visitor's perspective and expectations in mind, the long vacations in Lyamii were crafted by connecting cities in a rhythmic way. Each day of the whole 25-75 days of vacations has a unique tune in that rhythm, and that's what makes these long vacations a pure retreat for the visitors.",
  }
];
