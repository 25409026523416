import React, { FC } from "react";
import { Card, Col } from "react-bootstrap";

const PrivacyPolicies: FC = () => {
  return (
    <section className="bg-white align-items-center d-flex justify-content-center flex-column">
      <Col className="justify-content-center align-self-center pt-5 d-flex">
        <span className="fs-1 mt-5 mb-4 pl-4 text-decoration-underline col-12 text-dark fw-bold">
          Privacy Policy
        </span>
      </Col>

      {/* <Col className="col-10 d-flex flex-column pb-4">
        {" "}
        <Card.Text className="">
          <div  style={{ fontSize: "1.5dvi" }}>
            {privacyPolicies.description}
          </div>
        </Card.Text>
      </Col> */}
      <Col className="col-10 d-grid gap-2 flex-column">
        {/* {privacyPolicies.policies.map((policy: any, index: number) => {
          return (
            <Col className="pb-4">
              <Card.Title style={{ fontSize: "2dvi" }}>
                {policy.policy}
              </Card.Title>
              <Card.Text className="">
                {policy.text.map((text: any, index: number) => (
                  <div  style={{ fontSize: "1.5dvi" }}>
                    {text}
                  </div>
                ))}
              </Card.Text>
            </Col>
          );
        })} */}

        <Card.Title style={{ fontSize: "2dvi" }}>
          Introduction:
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          Lyamii (India) Private Limited (hereinafter referred as
          &lsquo;LYAMII&rsquo; or &lsquo;the Company&rsquo; or &lsquo;we&rsquo;)
          is committed to our client&rsquo;s privacy and security and to taking
          steps designed to secure your personal and financial information. We
          believe your private details should be kept just that and when you
          share personal information with us, we will make all reasonable
          endeavors to keep your details secure and private. We are committed to
          ensuring that your personal information is protected.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          This document describes how we use and process your personal data,
          provided in a readable and transparent manner. It also tells you what
          rights you can exercise in relation to your personal data and how you
          can contact us. Please also read our Cookie Statement, which tells you
          how LYAMII uses cookies and other similar tracking technologies.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          The processing of personal data when using the Services{" "}
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          LYAMII collects and uses information you provide to us. When you make
          a reservation, you are (at a minimum) asked for your name and email
          address.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Depending on the reservation, we may also ask for your home address,
          telephone number, payment information, date of birth, current location
          (in the case of on-demand services), the names of the people
          travelling/staying with you and any preferences you might have for
          your trip/stay (such as dietary or accessibility requirements). In
          some cases, you may also be able to check-in online with the Booking
          agency, for which we will ask you to share passport information or a
          driving license and signatures.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you need to contact our customer service team, contact your booking
          agency through us, or reach out to us in a different way (such as
          social media or via a chatbot) we&rsquo;ll collect information from
          you there, too. This applies whether you are contacting us with
          feedback or asking for help using our services.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          You might also be invited to write reviews to help inform others about
          the experiences you had on your trip/stay. When you write a review on
          the LYAMII platform, we&rsquo;ll collect any information you&rsquo;ve
          included, along with your display name.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you create a user account, we&rsquo;ll also store your personal
          settings, uploaded photos, and reviews of previous bookings. This
          saved data can be used to help you plan and manage future reservations
          or benefit from other features only available to account holders (such
          as incentives or other benefits).
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Personal data you give us about others
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          Of course, you might not simply be making a reservation for yourself.
          You might be taking a trip/stay with other people or making a
          reservation on someone else&rsquo;s behalf. In both those scenarios,
          you will provide their details as part of the reservation.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you have a LYAMII for Business account, you can keep an address
          book there to make it easier to plan and manage business travel/stay
          arrangements for others.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          In some cases, you might use LYAMII to share information with others.
          This can take the form of sharing a Wishlist, taking part in a travel
          community or participating in a referral program, as described when
          you use the relevant feature.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          At this point, we have to make it clear that it&rsquo;s your
          responsibility to ensure that the person or people you have provided
          personal data about are aware that you&rsquo;ve done so, and that they
          have understood and accepted how LYAMII uses their information (as
          described in this Privacy Policy).
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Personal data we collect automatically.
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          Whether or not you end up making a reservation, when you visit our
          websites or apps, we automatically collect certain information. This
          includes your IP address, the date and time you accessed our services,
          and information about your computer&rsquo;s hardware and software
          (such as the operating system, the internet browser used,
          software/application version data and your language settings). We also
          collect information about clicks and which pages have been shown to
          you.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you&rsquo;re using a mobile device, we collect data that identifies
          the device, as well as data about your device-specific settings and
          characteristics, app crashes and other system activity. When you make
          a booking arrangement using this kind of device, our system registers
          how you made your reservation (on which website), and/or which site
          you came from when you entered the LYAMII website or app.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Personal data we receive from other sources.
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          It&rsquo;s not just the things you tell us, though &ndash; we may also
          receive information about you from other sources. These include
          business partners, such as affiliate partners, subsidiaries of the
          LYAMII corporate group, other companies in the LYAMII (India) Private
          Limited. corporate group and other independent third parties.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Anything we receive from these partners may be combined with
          information provided by you. For example, LYAMII Reservation services
          are not only made available via LYAMII and the LYAMII apps but are
          also integrated into services of affiliate partners you can find
          online. When you use any of these services, you provide the
          reservation details to our business partners who then forward your
          details to us.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          We also integrate with third party service providers to facilitate
          payments between you and booking agencies. These service providers
          share payment information so we can administer and manage your Booking
          arrangement, making sure everything goes as smoothly as possible for
          you.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Additionally, we collect information in the regrettable case that we
          receive a complaint about you from a Booking agency, for example in
          the case of misconduct.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Another way we might receive data about you, is through the
          communication services integrated into our platforms. These
          communication services offer you a way to contact the booking agency
          you&rsquo;ve booked with to discuss your stay. In some cases, we
          receive metadata about these communication activities (such as who you
          are, where you called from, and the date and length of the call).
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Why do we collect and use your personal data?
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          We use the information collected about you for a variety of purposes.
          Your personal data may be used in the following ways:
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            <strong>Booking Arrangement:</strong> First and foremost, we use
            your personal data to complete and administer your online booking
            arrangement&ndash; which is essential for us to provide this service
            for you. This includes sending you communications that relate to
            your booking arrangement, such as confirmations, modifications, and
            reminders. In some cases, this may also include processing your
            personal data to enable online check-in with the booking agency or
            processing personal data in relation to damage deposits.
          </li>
          <li>
            <strong>Customer service:</strong> We provide international customer
            service from our local offices in more than twenty languages, and
            we&rsquo;re here to help 24 hours a day, 7 days a week. Sharing
            relevant details, such as reservation information or information
            about your user account with our global customer service staff
            allows us to respond when you need us. This includes helping you to
            contact the right booking agency and responding to any questions you
            might have about your booking arrangement (or any other queries, for
            that matter).
          </li>
          <li>
            <strong>Account facilities:</strong> LYAMII users can create an
            account on our website or apps. We use the information you give us
            to administer this account, allowing you to do several useful
            things. You can manage your booking arrangements, take advantage of
            unique offers, make future booking arrangements easily and manage
            your personal settings.
          </li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>
          Managing personal settings gives you the ability to keep and share
          lists, share photos, easily see trip/stay Services you&rsquo;ve
          searched for and check travel-related information you&rsquo;ve
          provided. You can also see any reviews you&rsquo;ve written.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you want to, you can share certain information as part of your user
          account, by creating a public profile under your own first name or a
          screen name you choose.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you&rsquo;re a LYAMII for Business account holder, you can also
          save contact details under that account, manage business reservations
          and link other account holders to the same LYAMII for Business
          account.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Online groups: We give account holders the chance to connect and
          interact with each other through online groups or forums, such as
          travel communities.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Marketing activities: We use your information for marketing
          activities. These activities include:
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            Using your contact information to send you regular news about
            travel-related products and services. You can unsubscribe from email
            marketing communications quickly, easily and at any time. All you
            need to do is click on the &lsquo;Unsubscribe&rsquo; link included
            in each newsletter or other communication.
          </li>
          <li>
            Based on your information, individualised offers might be shown to
            you on the LYAMII website, in mobile apps or on third-party
            websites/apps (including social media sites) and the content of the
            site displayed to you might be personalised. These could be offers
            that you can book directly on the LYAMII website, on co-branded
            sites, or other third-party offers or products we think you might
            find interesting.
          </li>
          <li>
            When you participate in other promotional activities (such as
            sweepstakes, referral programmes or competitions), only relevant
            information will be used to administer these promotions.
          </li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>
          Communicating with you: There might be other times when we get in
          touch, including by email, by chatbot, by post, by phone or by texting
          you. Which method we choose depends on the contact information
          you&rsquo;ve previously shared.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          We process the communications you send to us. There could be several
          reasons for this, including:
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Responding to and handling any requests you or your booking agency
          have made. LYAMII also offers customers and booking agencies several
          ways to exchange information, requests, and comments about Booking
          agencies and existing booking arrangements via LYAMII.
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            Providing the best price applicable to you, depending on where you
            are based: When you search our apps or website, for example to find
            an accommodation, a rental car, or a flight, we process your IP
            address to confirm whether you are in the European Economic Area
            (EEA) or in another country. We do this to offer you the best price
            for the region (EEA) or country (non-EEA) where you are based.
          </li>
          <li>
            Customer reviews and other destination-related information: During
            and after your trip/stay, we might invite you to submit a review. We
            can also make it possible for the people you&rsquo;re
            travelling/staying with or whom you&rsquo;ve booked a reservation
            for to do this instead. This invite asks for information about the
            booking agency or the destination.
          </li>
          <li>
            Call monitoring: When you make calls to our customer service team,
            LYAMII uses an automated telephone number detection system to match
            your telephone number to your existing reservations. This can help
            save time for both you and our customer service staff. However, our
            customer service staff may still ask for authentication, which helps
            to keep your reservation details confidential.
            <ul style={{ fontSize: "1.5dvi" }}>
              <li>
                During calls with our customer service team, live listening
                might be carried out or calls might be recorded for quality
                control and training purposes. This includes the usage of the
                recordings for the handling of complaints, legal claims and for
                fraud detection.
              </li>
              <li>
                We do not record all calls. In the case that a call is recorded,
                each recording is kept for a limited amount of time before being
                automatically deleted. This is unless we have determined that
                it&rsquo;s necessary to keep the recording for fraud
                investigation or legal purposes. You can read more about this
                below.
              </li>
              <li>
                Promotion of a safe and trustworthy service: To create a
                trustworthy environment for you, the people you bring with you
                on your trip/stay, LYAMII&rsquo;s business partners and our
                booking agencies, we continuously analyse and use certain
                personal data to detect and prevent fraud and other illegal or
                unwanted activities.
              </li>
              <li>
                Legal purposes: Finally, in certain cases, we may need to use
                your information to handle and resolve legal claims and
                disputes, for regulatory investigations and compliance, to
                enforce the LYAMII online reservation service terms of use or to
                comply with lawful requests from law enforcement.
              </li>
            </ul>
          </li>
        </ul>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Data sharing with third parties
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          In certain circumstances, we&rsquo;ll share your personal data with
          third parties. These third parties include:
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          The booking agency you booked: To complete your booking arrangement,
          we transfer relevant reservation details to the booking agency you
          have booked. This is one of the most essential things we do for you.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Depending on the booking arrangement and the Booking agency, the
          details we share can include your name, contact and payment details,
          the names of the people accompanying you and any other information or
          preferences you specified when you made your booking arrangement.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          In certain cases, we also provide some additional historical
          information about you to the Booking agency. This includes whether
          you&rsquo;ve already booked with them in the past, the number of
          completed bookings you&rsquo;ve made with LYAMII, a confirmation that
          no misconduct has been reported about you, the percentage of bookings
          you&rsquo;ve cancelled in the past or whether you&rsquo;ve given
          reviews about past bookings.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you have a query about your trip/stay, we may contact the booking
          agency to handle your request. Unless payment is made during the
          booking process, via the LYAMII website, we will forward your credit
          card details to the booking agency for further handling (assuming
          you&rsquo;ve provided us with those details).
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          In cases of Booking arrangement-related claims or disputes, we may
          provide the booking agency with your contact details and other
          information about the booking process, as needed to resolve the
          situation. This can include, but might not be limited to, your email
          address and a copy of your reservation confirmation as proof that a
          booking arrangement was made or to confirm reasons for cancellation.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          For completeness, booking agencies will further process your personal
          data outside of the control of LYAMII. Booking agencies may also ask
          for additional personal data, for instance to provide additional
          services, or to comply with local restrictions. If available, please
          read the Privacy Statement of the booking agency to understand how
          they process your personal data.
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            Connectivity Providers: Please note that certain booking agencies
            may need us to share your personal data with a contracted
            Connectivity Provider to be able to finalise and administer your
            reservation. Connectivity providers act on behalf of booking
            agencies and help them to manage their reservations.
          </li>
          <li>
            Your local LYAMII office: To support the use of LYAMII services,
            your details may be shared with subsidiaries of the LYAMII corporate
            group, including for customer service.
          </li>
          <li>
            Third-party service providers: We use service providers from outside
            of the LYAMII corporate group to support us in providing our
            services. These include:
            <ul style={{ fontSize: "1.5dvi" }}>
              <li>Customer support</li>
              <li>Market research</li>
              <li>
                Fraud detection and prevention (including anti-fraud screening)
              </li>
              <li>Insurance claims</li>
              <li>Payment</li>
            </ul>
          </li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>
          We use third parties to process payments, handle chargebacks or
          provide billing collection services. When a chargeback is requested
          for your booking arrangement, either by you or by the holder of the
          credit card used to make your reservation, we need to share certain
          reservation details with the payment service provider and the relevant
          financial institution so they can handle the chargeback. This may also
          include a copy of your reservation confirmation, or the IP address
          used to make your reservation. We may share information with relevant
          financial institutions if we consider it strictly necessary for fraud
          detection and prevention purposes.
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            Marketing services: We share personal data with advertising
            partners, including your email address, as part of marketing LYAMII
            services via third parties (to ensure that relevant advertisements
            are shown to the right audience). We use techniques such as hashing
            to enable the matching of your email address with an existing
            customer database, so that your email address cannot be used for
            other purposes. For information on other personalised advertisements
            and your choices, please read our cookie policy.
          </li>
          <li>
            Advertising partners: We use advertising partners, such as
            metasearch providers, to allow you to compare our offers with offers
            from other Online Travel Agencies (OTAs). When you make a
            reservation on LYAMII after using an advertising partner, we will
            send the details of the reservation that you made on LYAMII to that
            partner.
            <ul style={{ fontSize: "1.5dvi" }}>
              <li>
                Advertisers from Third Parties and Links to Other Websites: The
                LYAMII Services may have ads from third parties and links to
                other websites and apps. Advertising partners from outside our
                site may get information about you when you use their services,
                ads, and content.
              </li>
              <li>
                Use of Third-Party Advertising Services: We give ad companies
                information that helps them show you more useful and relevant
                ads and figure out how well they&apos;re doing. When we do this,
                we never give out your name or any other information that could
                be used to find you. We instead use an advertising identifier
                like a cookie, a device identifier, or a code that we get from
                encrypting other information, like an email address, in a way
                that can&apos;t be reversed. Even though we don&apos;t tell ad
                companies exactly what you searched for, we may give them an
                advertising identifier and an idea of how much the ads they show
                you are worth so they can show you more appropriate and
                effective ads. You may also see ads from other advertisers that
                are more useful to you from some ad companies.
              </li>
            </ul>
          </li>
          <li>
            Competent authorities: We disclose personal data to law enforcement
            to the extent that it is required by law or is strictly necessary
            for the prevention, detection or prosecution of criminal acts and
            fraud, or if we are otherwise legally obliged to do so. We may need
            to further disclose personal data to competent authorities to
            protect and defend our rights or properties, or the rights and
            properties of our business partners.
          </li>
          <li>
            Business partners: We work with many business partners around the
            world. These business partners distribute and advertise LYAMII
            services, including the services and products of our booking
            agencies. When you make a reservation on one of our business
            partners&rsquo; websites or apps, certain personal data that you
            give them, such as your name and email address, your address,
            payment details and other relevant information, will be forwarded to
            us to finalise and manage your booking arrangement. If customer
            service is provided by the business partner, LYAMII will share
            relevant reservation details with them (as and when needed) to
            provide you with appropriate and efficient support.
          </li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>
          When you make a reservation through one of our business
          partners&rsquo; websites, the business partners can receive certain
          parts of your personal data related to the specific reservation and
          your interactions on these partner websites. This is for their
          commercial purposes. When you make a reservation on a business
          partners&rsquo; website, please also take the time to read their
          privacy notice if you&rsquo;d like to understand how they process your
          personal data.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          For fraud detection and prevention purposes, we may also exchange
          information about our users with business partners &ndash; but only
          when strictly necessary If an insurance claim is made, concerning you
          and a Booking agency, we may provide the necessary data (including
          personal data) to the insurance company for further processing.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Partner Offer: We may present you with a &lsquo;Partner Offer. When
          you book a stay marked &lsquo;Partner Offer,&rsquo; your reservation
          will be facilitated by a booking agency who is separate from the
          accommodation you&rsquo;re booking. As part of the reservation
          process, we&rsquo;ll need to share some relevant personal data with
          this business partner. If you book a Partner Offer, please review the
          information provided in the booking process or check your reservation
          confirmation for more information about the booking agency and how
          your personal data will be further processed by them.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          LYAMII is a global business. The data that we collect from you, as
          described in this Privacy Statement, could be made accessible from,
          transferred to, or stored in countries which may not have the same
          data protection laws as the country in which you initially provided
          the information. In such cases, we will protect your data as described
          in this Privacy Statement.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          This may also be applicable if you are in the European Economic Area
          (EEA). Countries your data may be transferred to may not have laws
          that provide the same level of protection for your personal data as
          laws within the EEA. Where this is the case, we will put appropriate
          safeguards in place to make sure that these transfers comply with
          European privacy law.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          When your data is transferred to third-party service providers, we
          establish and implement appropriate contractual, organisational, and
          technical measures with them. This is done by putting in place
          Standard Contractual Clauses as approved by the European Commission,
          by examining the countries to which the data may be transferred, and
          by imposing specific technical and organisational security measures.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          In certain specific cases, we transfer your data outside the EEA
          because it is in your interest or is necessary to conclude or perform
          the contract, we have with you. For instance, when you make a
          reservation on LYAMII or through a business partner, we might need to
          transfer your data to a booking agency or business partner who is
          located outside the EEA
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          How is personal data shared within the LYAMII corporate group?
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          We may receive personal data about you from other companies in the
          LYAMII (India) Private Limited. corporate group, or share your
          personal data with them, for the following purposes:
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            To provide services (including to make, administer and manage
            reservations or handle payments);
          </li>
          <li>To provide customer service;</li>
          <li>
            To detect, prevent and investigate fraudulent, other illegal
            activities and data breaches;
          </li>
          <li>For analytical and product improvement purposes;</li>
          <li>
            To provide personalised offers or send you marketing with your
            consent or as otherwise permitted by applicable law;
          </li>
          <li>
            For hosting, technical support, overall maintenance and maintaining
            security of such shared data;
          </li>
          <li>To ensure compliance with applicable laws;</li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>
          As applicable and unless indicated otherwise, for purposes A to F,
          LYAMII relies on its legitimate interests to share and receive
          personal data. For purpose G, LYAMII relies, where applicable, on
          compliance with legal obligations (such as lawful law enforcement
          requests).
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          How is your personal data shared and further processed for insurance
          services?
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          When offering insurance, LYAMII may have to use and share personal
          data that is relevant to the insurance product. This data relates to
          you as a potential or actual policyholder, the beneficiaries under a
          policy, family members, claimants and other parties involved in a
          claim:
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            To provide offers, arrange insurance cover and handle insurance
            claims, some personal data, provided to us during the booking
            process, (&lsquo;General Order Data&rsquo;) may have to be shared
            with LYAMII. You may also be asked to provide additional
            information, such as names of family members or other beneficiaries
            or details about a claim (&lsquo;Insurance-Specific Data&rsquo;).
          </li>
          <li>
            If you make a claim under an insurance policy, this claim may be
            directly handled by the insurer. This means that you may be asked to
            provide personal data to submit the claim directly to them. The
            insurer will inform you accordingly at the point of collection of
            your information. When your claim is handled by the insurer, LYAMII
            may receive information about the status of your claim to provide
            you with customer support services.
          </li>
        </ul>
        <Card.Title style={{ fontSize: "2dvi" }}>
          How does LYAMII process communications that you and your booked agency
          may send via LYAMII?
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          LYAMII can offer you and booking agencies several ways to communicate
          about the trip/stay Services and existing booking arrangements,
          directing the communications via LYAMII. This also allows you and your
          booking agency to contact LYAMII with questions about your booking
          arrangement through the website, our apps, and the other channels that
          we make available.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          LYAMII accesses communications and may use automated systems to
          review, scan, and analyse communications for the following reasons:
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>Security purposes;</li>
          <li>Fraud prevention;</li>
          <li>Compliance with legal and regulatory requirements;</li>
          <li>Investigations of potential misconduct;</li>
          <li>Product development and improvement;</li>
          <li>Research;</li>
          <li>
            Customer engagement (including to provide you with information and
            offers that we believe may be of interest to you)
          </li>
          <li>Customer or technical support</li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>
          We reserve the right to review or block the delivery of communications
          that we, in our sole discretion, believe might contain malicious
          content or spam, or pose a risk to you, booking agencies, LYAMII or
          others.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          All communications sent or received using LYAMII communication tools
          will be received and stored by LYAMII. Booking agencies and Business
          partners you&rsquo;ve booked a booking arrangement through might also
          choose to communicate with you directly by email or through other
          channels that LYAMII does not control.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          How does LYAMII make use of social media?
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          At LYAMII, we use social media in separate ways. We use it to
          facilitate the use of online reservation services, but also to promote
          our booking agencies&rsquo; travel-related products and services and
          to advertise, improve and facilitate our own services.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Note that the use of social media features can result in the exchange
          of personal data between LYAMII and the social media service provider,
          as we describe below. You are free not to use any of the social media
          features available to you.
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            Sign in with your social media account. We offer you the opportunity
            to sign in to a LYAMII user account with one of your social media
            accounts. We do this to reduce the need for you to remember
            different usernames and passwords for different online services.
            After you&rsquo;ve signed in once, you&rsquo;ll be able to use your
            social media account to sign into your LYAMII account. You can
            decouple your LYAMII user account from your chosen social media
            account any time you want to.
          </li>
          <li>
            Integration of social media plugins. We have also integrated social
            media plugins into the LYAMII website and apps. This means that when
            you click or tap on one of the buttons (such as Facebook&rsquo;s
            &lsquo;Like&rsquo; button), certain information is shared with these
            social media providers. If you&rsquo;re logged into your social
            media account when you click or tap one of these buttons, your
            social media provider may relate this information to your social
            media account. Depending on your settings, they might also display
            these actions on your social media profile, to be seen by others in
            your network.
          </li>
          <li>
            Other social media services and features. We may integrate other
            social media services (like social media messaging) for you to
            interact with LYAMII or with your contacts about our services. We
            may maintain social media accounts and offer apps on several social
            media sites. Whenever you connect with LYAMII through social media,
            your social media service provider may allow you to share
            information with us.
          </li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>
          If you choose to share, your social media provider will inform you
          about which information will be shared. For example, when you sign
          into a LYAMII user account using your social media account, certain
          information from that account may be shared with LYAMII. This includes
          your email address, your age, and the profile pictures you&rsquo;ve
          saved &ndash; depending on what you authorize in your social media
          account.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          When you register with a LYAMII social media app or connect to a
          social media messaging service without a LYAMII user account, the
          information you choose to share with us may include the basic
          information available in your social media profile (including your
          email address, status updates and a list of your contacts).
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          We&rsquo;ll use this information to help provide you with the service
          you requested &ndash; for example, to forward a message you want to
          send to your contacts, or to create a personalised user experience in
          the app or on our websites. It means that if you want us to, we can
          tailor our services to suit your needs, connecting you and your
          friends with the best travel destinations and analysing and improving
          our travel-related services.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Your social media provider will be able to tell you more about how
          they use and process your data when you connect to LYAMII through
          them. This can include combining the personal data they collect when
          you use LYAMII through them with information they collect when you use
          other online platforms you have also linked to your social media
          account.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you decide to connect using your Facebook or Google account, please
          review the following links for information about how these parties use
          data they receive: Facebook and Google.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          What security and retention procedures does LYAMII put in place to
          safeguard your personal data?
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          We have procedures in place to prevent unauthorized access to, and the
          misuse of, personal data.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          We use appropriate business systems and procedures to protect and
          safeguard the personal data you give us. We also use security
          procedures and technical and physical restrictions for accessing and
          using the personal data on our servers. Only authorized personnel are
          permitted to access personal data in the course of their work.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          We&rsquo;ll keep your personal data for as long as is necessary to
          enable you to use our services or to provide our services to you
          (including maintaining any LYAMII user accounts you may have), to
          comply with applicable laws, resolve any disputes and otherwise to
          allow us to conduct our business, including to detect and prevent
          fraud and/or other illegal activities. All personal data we keep about
          you as a LYAMII customer is covered by this Privacy Statement.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          For added protection, we strongly recommend that you set up two-factor
          authentication for your LYAMII user account. This adds an extra
          authentication step, to make sure that anyone who gets hold of your
          username and password (e.g., through phishing or social engineering)
          won&rsquo;t be able to get into your account. You can set this up in
          the Security section of your account settings.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          How does LYAMII treat personal data belonging to children?
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          Our services aren&rsquo;t intended for children under 16 years old,
          and we&rsquo;ll never collect their data unless it&rsquo;s provided by
          (and with the consent of) a parent or guardian. The limited
          circumstances we might need to collect the personal data of children
          under 16 years old include: as part of a reservation, the purchase of
          other travel-related services, or in other exceptional circumstances
          (such as features addressed to families). Again, this will only be
          used and collected as provided by a parent or guardian and with their
          consent.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If we become aware that we&rsquo;ve processed the information of a
          child under 16 years old without the valid consent of a parent or
          guardian, we will delete it.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>Your rights </div>
        <div style={{ fontSize: "1.5dvi" }}>
          You have the following rights with respect to your personal data:{" "}
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>General rights</Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          You have the right to information, access, correction, deletion,
          restriction of processing, objection to processing, and data
          portability. If processing is based on your consent, you have the
          right to revoke it at any time.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Rights to object to processing of data based on legitimate interests
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          Article 21(1) EU General Data Protection Regulation (EU) 2016/679
          (&ldquo;GDPR&rdquo;) gives you the right to object at any time for
          reasons arising out of your particular situation against the
          processing of personal data relating to you when your data is
          processed under Article 6(1)(e) or Article 6 (1)(f) GDPR. This also
          applies to profiling. If you object, we will no longer process your
          personal data unless we can establish compelling and legitimate
          grounds for processing that outweigh your interests, rights, and
          freedoms, or if the processing aids the enforcing, exercising, or
          defending of legal claims.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Rights to object to direct marketing
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          If we process your personal data for the purpose of direct marketing
          Article 21(2) GDPR gives you the right to object at any time to the
          processing of your personal data for the purpose of direct marketing;
          this also applies to profiling, as far as it is associated with direct
          marketing.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you object to processing for the purpose of direct marketing, we
          will no longer process your personal data for this purpose.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          The Data Rights of the Citizens of California
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          You may have certain data rights under state privacy laws, such as the
          ability to request information regarding the manner in which LYAMII
          collects your personal information, to obtain your personal
          information in a portable format, and to have your personal
          information amended or deleted.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          {`Additionally, you may have the right to opt out of the processing of
          your personal data for targeted advertising, by managing your browser
          settings or by contacting us at ${(
            <span
              onClick={(e) => {
                window.location.href = "mailto:Connect.helpdesk@lyamii.com";
                e.preventDefault();
              }}
            >
              connect.helpdesk@lyamii.com
            </span>
          )}`}
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Consent is required for customers under the age of 16 to be exposed to
          cross-context behavioural advertising. We do not serve cross-context
          behavioural ads in customers&apos; child profiles.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If we deny a data request, you to appeal the denial of any of these
          rights by completing a form that we will furnish you with. Depending
          on the data options selected, specific services might be restricted or
          inaccessible. We will typically request that you authenticate your
          request using the contact information you have already provided in
          order to safeguard your LYAMII account.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          No sale of personal information.
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          Personal information of consumers, as defined by the California
          Consumer Privacy Rights Act, has not been sold by LYAMII as of now.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Sensitive Personal Information Disclosure.
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          LYAMII gathers and divulges certain types of data for some business
          purposes which includes &quot;sensitive personal information&quot; but
          this Sensitive personal information is not utilized or disclosed by
          LYAMII for any purpose that is not explicitly authorized by the
          California Consumer Privacy Rights Act.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Retention Disclosure
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          In order to facilitate your ongoing utilization of LYAMII Services, we
          shall retain your personal information for the duration necessary to
          fulfil the objectives outlined in this Privacy Notice, in accordance
          with legal requirements, as otherwise instructed to you.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Non-discrimination Declaration
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          Any consumer who exercises their rights under the California Consumer
          Privacy Rights Act will not be subject to discrimination at LYAMII.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          How can you exercise your rights and control the personal data
          you&rsquo;ve given to LYAMII?
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          We want you to be in control of how your personal data is used by us.
          You can do this in the following ways:
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            You can ask us for a copy of the personal data we hold about you,
          </li>
          <li>
            You can inform us of any changes to your personal data, or you can
            ask us to correct any of the personal data we hold about you. As
            explained below, you can make some of these changes yourself,
            online, when you have a user account,
          </li>
          <li>
            In certain situations, you can ask us to erase, block, or restrict
            the processing of the personal data we hold about you, or object to
            ways in which we are using your personal data,
          </li>
          <li>
            In certain situations, you can also ask us to send the personal data
            you have given us to a third party,
          </li>
          <li>
            Where we are using your personal data based on your consent, you are
            entitled to withdraw that consent at any time subject to applicable
            law, and
          </li>
          <li>
            Where we process your personal data based on legitimate interest or
            the public interest, you have the right to object to that use of
            your personal data at any time, subject to applicable law.
          </li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>
          We rely on you to make sure that your personal information is
          complete, accurate and current. Please do let us know about any
          changes to, or inaccuracies in, your personal information as soon as
          possible.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          If you have a LYAMII user account, you can access a lot of your
          personal data through our website or apps. You&rsquo;ll find the
          option to add, update or remove information we have about you in your
          account settings.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>Contact by e-mail</Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          When you contact us by e-mail, we will store the data you provide
          (your e-mail address, possibly your name and telephone number) so we
          can answer your questions. As far as we use our contact form to
          request entries that are not required for contacting you, we have
          always marked these as optional. This information serves to
          substantiate your inquiry and improve the handling of your request.
          Your message may be linked to various actions taken by you on the
          LYAMII website. Information collected will be solely used to provide
          you with support relating to your booking and better understand your
          feedback. A statement of this information is expressly provided on a
          voluntary basis and with your consent, art. 6 par. 1a GDPR. As far as
          this concerns information about communication channels (such as your
          e-mail address or telephone number), you also agree that we may also,
          where appropriate, contact you via this communication channel to
          answer your request. You may of course revoke this consent for the
          future at any time.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          We delete the data that arises in this context after saving is no
          longer required, or limit processing if there are statutory retention
          requirements.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>Use of cookies</Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          Cookies will be stored on your device during the use of our website.
          Cookies are small text files that are stored on your hard drive
          assigned to the browser you use, and through which the place where the
          cookie is set accrues certain information. They serve to make the
          website more user-friendly and efficient overall. We also use cookies
          to be able to identify you in subsequent visits.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          This website uses the following types of cookies, whose extent and
          function are explained in the following:
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            <strong>Persistent / Permanent cookies:</strong>Persistent cookies
            helps us recognise you as an existing user, so it&rsquo;s easier for
            you to return to our sales channel without signing in again. After
            signing in, the persistent cookies stay on your browser and will be
            read when you return to our sales channel. These remain on your
            computer/device for a pre-defined period.
          </li>
          <li>
            <strong>Session cookies:</strong>Session cookies only last for as
            long as the session exists, (they are erased when the user closes
            the browser).
          </li>
        </ul>
        <Card.Title style={{ fontSize: "2dvi" }}>Data transmission</Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          When we use service providers who process data outside the EU/EEA, we
          use appropriate safeguards such as standard data protection clauses
          adopted by the EU, or an EU adequacy decision to transfer and process
          your personal data outside of the EU.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>Data security</Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          We have taken extensive technical and operational security precautions
          to protect your data from being accidentally or intentionally
          manipulated, lost, destroyed, or accessed by unauthorized persons. Our
          security measures are reviewed regularly and updated in keeping with
          technological advances.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>Data Storage</Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          We retain your personal data for as long as it is required for you to
          use our Services, to provide our Services to you, to comply with laws.
          We will anonymize and/or aggregate your data if we intend to use it
          for analytical statistical purposes over longer periods.
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>Data Retention</Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          We will retain the personal information we collect from you where we
          have a justifiable business need to do so and/or for as long as is
          needed to fulfil the purposes outlined in this Privacy Policy, unless
          a longer retention period is required or permitted by law (such as
          tax, legal, accounting, or other purposes).
        </div>
        <Card.Title style={{ fontSize: "2dvi" }}>
          Notice for Individuals Located in Europe
        </Card.Title>
        <div style={{ fontSize: "1.5dvi" }}>
          &lsquo;If you are in the European Economic Area, United Kingdom and/or
          Switzerland, the policies in this section are specific to you. They
          describe how we market to you, our legal bases for processing your
          information and your rights.&rsquo;
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          This section only applies to individuals that access or use our
          Services while located in the European Economic Area, United Kingdom
          and/or Switzerland (collectively &ldquo;Europe&rdquo;). We may ask you
          to identify which country you are in when you use some of the Services
          or we may rely on your IP address to identify in which country you are
          located. When we rely on your IP address, we cannot apply the terms of
          this section to any individual that masks or otherwise hides their
          location information from us so as not to appear located in Europe.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          We are a controller regarding any personal information collected from
          individuals accessing or using our Services. A
          &ldquo;controller&rdquo; is an entity that determines the purposes for
          which and the way any personal information is processed.
        </div>
        <ul style={{ fontSize: "1.5dvi" }}>
          <li>
            Marketing. We will only contact individuals located in Europe by
            electronic means (including email or SMS) based on our legitimate
            interests, as permitted by applicable law or the individual&rsquo;s
            consent. When we rely on legitimate interest, we will only send you
            information about our Services that are like those which were the
            subject of a previous offer or negotiations of an offer to you. If
            you do not want us to use your personal information in this way or
            to disclose your personal information to third parties for marketing
            purposes. You can object to direct marketing at any time and free of
            charge. Direct marketing includes any communications to you that are
            only based on advertising or promoting products and services.
          </li>
          <li>
            Legal Bases for Processing. Our legal bases for the processing
            activities identified in this Policy are:
            <ul style={{ fontSize: "1.5dvi" }}>
              <li>
                We rely on our contract with you as our legal basis for
                processing in relation to the following: to provide and maintain
                our services, to provide customer support or respond to you, to
                enforce compliance with our Terms, agreements, or policies, and
                to share your information with service providers.
              </li>
              <li>
                Depending on the specific circumstances, we rely on your consent
                or legitimate interest in relation to the following processing
                activities: to send you marketing and promotional emails, to
                advise you of other services, and to share your information with
                business partners, sponsors, or within our corporate
                organization.
              </li>
              <li>
                We rely on legitimate interest in relation to the following
                processing activities: to personalize your experience, for
                research and development, and when we share your information
                with board members or volunteers or in relation to business
                transfers or bankruptcy.
              </li>
            </ul>
          </li>
        </ul>
        <div style={{ fontSize: "1.5dvi" }}>How to contact us </div>
        <div style={{ fontSize: "1.5dvi" }}>
          {`LYAMII&rsquo;s Data Protection Officer is responsible for monitoring
          compliance with relevant legislation in relation to the protection of
          personal data. Please contact us at ${(
            <span
              onClick={(e) => {
                window.location.href = "mailto:Connect.helpdesk@lyamii.com";
                e.preventDefault();
              }}
            >
              connect.helpdesk@lyamii.com
            </span>
          )}&nbsp;You can
          contact us for any concerns or questions about your personal
          information, those we process or store.`}
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          Our cookie statement may also be updated from time to time. If these
          updates are substantial, particularly relevant to you or impact your
          data protection rights, we&rsquo;ll contact you about them. However,
          we recommend that you visit this page regularly to stay up to date
          with any other (less substantial or relevant) updates.
        </div>
        <div style={{ fontSize: "1.5dvi" }}>
          <br />
        </div>
      </Col>
    </section>
  );
};

export default PrivacyPolicies;
